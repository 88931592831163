import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import clsx from 'clsx'
// import CryptoJS from 'crypto-js'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import configuration from 'src/configuration'
import {
  AppBar,
  colors,
  IconButton,
  Toolbar,
  Hidden
} from '@material-ui/core'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import ProfilePopover from 'src/components/ProfilePopover'
import ls from 'local-storage'
import { clearManageUsers, clearMarketplace, logout } from 'src/actions'
import { red } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'
import Badge from '@material-ui/core/Badge'
import NotificationsPopover from 'src/components/NotificationsPopover'
import moment from 'moment'
import ChangeLanguageButton from 'src/components/ChangeLanguageButton'
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    background: 'linear-gradient(90deg, #d62929, #4b1e82)'
  },
  flexGrow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  profileMenuButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    height: '24px',
    width: 'auto'
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  whiteIcon: {
    color: 'white'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 255,
    height: 64
    // marginLeft: -24,
    // backgroundColor: '#fafafa',
    // borderRight: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`
  },
  inlineGuide: {
    color: red[100],
    transition: 'color 0.2s',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.common.white,
      transition: 'color 0.2s, text-decoration 0.2s'
    }
  }
}))

function TopBar ({
  onOpenNavBarMobile,
  onOpenNavBarDesktop,
  onCloseNavBarDesktop,
  isNavBarDesktopOpen,
  className,
  ...rest
}) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const menuRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(false)
  const session = useSelector(state => state.session)
  const { user, currentCompany } = session
  const { t } = useTranslation('topBar')
  const [notifications, setNotifications] = useState([
  ])
  useEffect(() => {
    if (currentCompany.isSupplier) {
      setNotifications([{
        id: currentCompany.uuid,
        title: t('setDiscountNotificationText'),
        type: 'feature',
        created_at: moment().subtract(2, 'hours'),
        link: `/discountPreferences/setPreferences?supplierId=${currentCompany.uuid}`
      }])
    }
  }, [currentCompany, t])
  const notificationsRef = useRef(null)
  const [openNotifications, setOpenNotifications] = useState(false)

  const handleNotificationsOpen = () => {
    setOpenNotifications(true)
  }
  const handleNotificationsClose = () => {
    setOpenNotifications(false)
  }
  // const permissions = user && user.role && user.role
  //   .filter(companyPermission => companyPermission.companyId === currentCompany.uuid)
  //   .map(permission => permission.role)

  // const showInlineGuide = permissions && !(
  //   permissions.includes('BuyerAdminFull') ||
  //   permissions.includes('BuyerAdminReadOnly') ||
  //   permissions.includes('BuyerFinancialFull') ||
  //   permissions.includes('BuyerFinancialReadOnly') ||
  //   permissions.includes('BuyerTechnicalFull') ||
  //   permissions.includes('BuyerTechnicalReadOnly'))
  useEffect(() => {
    if (user && user.email && currentCompany && currentCompany.name) {
      const tags = []
      const {
        isBuyer,
        isSupplier,
        name
      } = currentCompany
      const {
        email
      } = user

      if (currentCompany.isBuyer) {
        tags.push('buyer')
      }
      if (currentCompany.isSupplier) {
        tags.push('supplier')
      }
      try {
        if (window.fcWidget) {
          // console.log('Widget Loaded')
          window.fcWidget.destroy()
          window.fcWidget.on('widget:destroyed', function (resp) {
            // console.log('User Created')
            window.fcWidget.init({
              host: 'https://wchat.eu.freshchat.com',
              open: false,
              locale: 'it',
              token: configuration.freshChatKey,
              externalId: user.uuid,
              email,
              firstName: name,
              meta: {
                isBuyer,
                isSupplier,
                vatId: currentCompany.vatId
              },
              faqTags: {
                tags,
                filterType: 'category'
              }
            })
          })
        }
        // if (window.fcWidget) {
        //
        // } else {
        //   console.log('freshchat not ready')
        //   setTimeout(() => {
        //     if (window.fcWidget) {
        //       window.fcWidget.destroy()
        //       window.fcWidget.on('widget:destroyed', function (resp) {
        //         console.log('User Created')
        //         window.fcWidget.init({
        //           host: 'https://wchat.eu.freshchat.com',
        //           open: false,
        //           locale: 'it',
        //           token: configuration.freshChatKey,
        //           externalId: user.uuid,
        //           email,
        //           firstName: name,
        //           meta: {
        //             isBuyer,
        //             isSupplier,
        //             vatId: currentCompany.vatId
        //           },
        //           faqTags: {
        //             tags,
        //             filterType: 'category'
        //           }
        //         })
        //       })
        //     }
        //   }, 1000)
        // }
      } catch (e) {
        console.log('Freshchat err', e)
      }
    }
    // eslint-disable-next-line
  }, [user, currentCompany])
  const handleLogout = () => {
    ls.clear()
    dispatch(clearManageUsers())
    dispatch(clearMarketplace())
    dispatch(logout())
    history.push('/auth/login')
  }

  const handleMenuOpen = () => {
    setOpenMenu(true)
  }

  const handleMenuClose = () => {
    setOpenMenu(false)
  }
  //
  // const activateInlineGuide = () => {
  //   const guideType = history.location.pathname.includes('/marketplace/details/') ? 'details' : history.location.pathname.includes('/marketplace') ? 'marketplace' : null
  //
  //   if (guideType && guideType === 'details') {
  //     dispatch(setDetailsTourDone(offerStatus))
  //   } else if (guideType && guideType === 'marketplace') {
  //     dispatch(setMarketplaceTourDone())
  //   }
  // }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color='primary'
    >
      <Toolbar>
        <div className={classes.logoContainer}>
          <Hidden mdUp>
            <IconButton
              className={classes.menuButton}
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon className={classes.whiteIcon} />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            {!isNavBarDesktopOpen ? (
              <IconButton
                className={classes.menuButton}
                onClick={onOpenNavBarDesktop}
              >
                <MenuIcon className={classes.whiteIcon} />
              </IconButton>
            ) : null}
          </Hidden>
          <Hidden smDown>
            <RouterLink to='/'>
              <img
                className={classes.logo}
                alt='Logo'
                src='/images/logo-plusadvance-white.png'
              />
            </RouterLink>
          </Hidden>
          <Hidden smDown>
            {isNavBarDesktopOpen ? (
              <IconButton
                onClick={onCloseNavBarDesktop}
              >
                <ChevronLeftIcon className={classes.whiteIcon} />
              </IconButton>
            ) : null}
          </Hidden>
        </div>
        <div className={classes.flexGrow} />
        {/* { */}
        {/*  history.location.pathname.includes('/marketplace') && showInlineGuide ? <Typography */}
        {/*    onClick={activateInlineGuide} className={classes.inlineGuide} */}
        {/*    variant='overline' */}
        {/*  >{t('inlineGuideText')} */}
        {/*  </Typography> : null */}
        {/* } */}
        <ChangeLanguageButton />
        <IconButton
          className={classes.notificationsButton}
          color='inherit'
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={notifications.length}
            classes={{ badge: classes.notificationsBadge }}
            variant='dot'
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.profileMenuButton}
          color='inherit'
          onClick={handleMenuOpen}
          ref={menuRef}
        >
          <ProfileIcon />
        </IconButton>
      </Toolbar>
      <ProfilePopover
        anchorEl={menuRef.current}
        onClose={handleMenuClose}
        handleLogout={handleLogout}
        open={openMenu}
      />
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
}

export default TopBar
