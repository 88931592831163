import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import SSOLayout from './layouts/SSO'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'
import DiscountPreferencesLayout from './layouts/DiscountPreferences'
import PaymentCheckoutLayout from './layouts/PaymentCheckout'
import InvitationLayout from './layouts/Invitation'
import CreateUserView from './views/CreateUserView'
import InviteUserView from './views/InviteUserView'
import ActivateUserView from './views/ActivateUser'
import ResetPasswordView from './views/ResetPasswordView'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/home' />
  },
  {
    path: '/resetpassword/:resetToken/:email',
    exact: true,
    component: ResetPasswordView
  },
  {
    path: '/activate/:activateToken/:email',
    exact: true,
    component: ActivateUserView
  },
  {
    path: '/pec/:activateToken/:email',
    exact: true,
    component: ActivateUserView
  },
  {
    path: '/invitations',
    component: InvitationLayout,
    routes: [
      {
        path: '/invitations/:id',
        exact: true,
        component: lazy(() => import('src/views/Invitation'))
      }
    ]
  },
  {
    path: '/sso',
    component: SSOLayout,
    routes: [
      {
        path: '/sso/firstAccess',
        exact: false,
        component: lazy(() => import('src/views/SSOFirstAccess'))
      },
      {
        path: '/sso/login',
        exact: false,
        component: lazy(() => import('src/views/SSOLogin'))
      }
    ]
  },
  {
    path: '/advancedPayments',
    component: PaymentCheckoutLayout,
    routes: [
      {
        path: '/advancedPayments/:paymentId',
        exact: true,
        component: lazy(() => import('src/views/PaymentCheckout'))
      },
      {
        path: '/advancedPayments/:paymentId/receipt',
        exact: true,
        component: lazy(() => import('src/views/PaymentReceipt'))
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        path: '/auth/login/mfa',
        exact: true,
        component: lazy(() => import('src/views/MFALogin'))
      },
      {
        path: '/auth/loginPA',
        exact: true,
        component: lazy(() => import('src/views/LoginPA'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        path: '/auth/forgotpassword',
        exact: true,
        component: lazy(() => import('src/views/ForgotPassword'))
      },
      {
        component: () => <Redirect to='/errors/error-404' />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to='/errors/error-404' />
      }
    ]
  },
  {
    path: '/discountPreferences',
    component: DiscountPreferencesLayout,
    routes: [
      {
        path: '/discountPreferences/setPreferences',
        exact: true,
        component: lazy(() => import('src/views/DiscountPreferences'))
      },
      {
        path: '/discountPreferences/saveSurvey',
        exact: true,
        component: lazy(() => import('src/views/SaveSurvey'))
      },
      {
        path: '/discountPreferences/submitFeedback',
        exact: true,
        component: lazy(() => import('src/views/SubmitFeedback'))
      },
      {
        path: '/discountPreferences/authorizeFeedbackPublication',
        exact: true,
        component: lazy(() => import('src/views/PublicateFeedback'))
      },
      {
        path: '/discountPreferences/saveFeedback',
        exact: true,
        component: lazy(() => import('src/views/SaveFeedback'))
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/funders/home',
        exact: true,
        component: lazy(() => import('src/views/HomeFunder'))
      },
      {
        path: '/funders/program/:programId/dashboard',
        exact: true,
        component: lazy(() => import('src/views/ProgramDashboardFunder'))
      },
      {
        path: '/funders/program/:programId/history',
        exact: true,
        component: lazy(() => import('src/views/ProgramDashboardFunder'))
      },
      {
        path: '/funders/program/:programId/suppliers',
        exact: true,
        component: lazy(() => import('src/views/ProgramSuppliersFunder'))
      },
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('src/components/DashboardRoleSwitcher'))
      },
      {
        path: '/reverse-dynamic-discounting/uploadInvoices',
        exact: true,
        component: lazy(() => import('src/views/LoadInvoiceReverseDD'))
      },
      {
        path: '/reverse-dynamic-discounting/paymentRequests',
        exact: true,
        component: lazy(() => import('src/views/SentPaymentRequest'))
      },
      {
        path: '/reverse-dynamic-discounting',
        exact: true,
        component: lazy(() => import('src/views/ReverseDynamicDiscounting'))
      },
      {
        path: '/dynamic-request-to-pay/billing',
        exact: true,
        component: lazy(() => import('src/views/BillingDynamicRequestToPay'))
      },
      {
        path: '/reverse-dynamic-discounting/pisps',
        exact: true,
        component: lazy(() => import('src/views/ReverseDynamicDiscountingPisps'))
      },
      {
        path: '/reverse-dynamic-discounting/selectUploadMethod',
        exact: true,
        component: lazy(() => import('src/views/SelectOptionToUploadInvoices'))
      },
      {
        path: '/reverse-dynamic-discounting/bulkUpload',
        exact: true,
        component: lazy(() => import('src/views/LoadInvoiceReverseDD'))
      },
      {
        path: '/reverse-dynamic-discounting/uploadInvoicesFromExternalService',
        exact: true,
        component: lazy(() => import('src/views/LoadInvoiceExternalService'))
      },
      {
        path: '/reverse-dynamic-discounting/businessRelationship/:relationshipId/debtor/dashboard',
        exact: true,
        component: lazy(() => import('src/views/ReverseDDRelationshipDashboardDebtor'))
      },
      {
        path: '/reverse-dynamic-discounting/businessRelationship/:relationshipId/creditor/dashboard',
        exact: true,
        component: lazy(() => import('src/views/ReverseDDRelationshipDashboardCreditor'))
      },
      {
        path: '/reverse-dynamic-discounting/businessRelationship/:relationshipId/creditor/addInvoices',
        exact: true,
        component: lazy(() => import('src/views/UploadInvoicesInBusinessRelationship'))
      },
      {
        path: '/reverse-dynamic-discounting/businessRelationship/:relationshipId/creditor/archive',
        exact: true,
        component: lazy(() => import('src/views/RelationshipArchiveCreditor'))
      },
      {
        path: '/reverse-dynamic-discounting/businessRelationship/:relationshipId/paymentRequest/:requestId/finalize',
        exact: true,
        component: lazy(() => import('src/views/ReverseDDRelationshipProposalCreation'))
      },
      {
        path: '/users/details/:id',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/suppliers/details/:id',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/users/create',
        exact: true,
        component: CreateUserView
      },
      {
        path: '/users/invite',
        exact: true,
        component: InviteUserView
      },
      {
        path: '/users',
        exact: true,
        component: lazy(() => import('src/views/ManageUsers'))
      },
      {
        path: '/activations',
        exact: true,
        component: lazy(() => import('src/views/ManageActivations'))
      },
      {
        path: '/invitedusers',
        exact: true,
        component: lazy(() => import('src/views/ManageInvitations'))
      },
      {
        path: '/addBuyers',
        exact: true,
        component: lazy(() => import('src/views/InviteClientsNew'))
      },
      {
        path: '/suppliers',
        exact: true,
        component: lazy(() => import('src/views/ManageSuppliersList'))
      },
      {
        path: '/suppliers/home',
        exact: true,
        component: lazy(() => import('src/views/HomeSupplier'))
      },
      {
        path: '/suppliers/archive',
        exact: true,
        component: lazy(() => import('src/views/SupplierAuctionsArchive'))
      },
      {
        path: '/manageSuppliers/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/ManageSuppliersDetails'))
      },
      {
        path: '/buyers',
        exact: true,
        component: lazy(() => import('src/views/ManageUsers'))
      },
      {
        path: '/buyers/home',
        exact: true,
        component: lazy(() => import('src/views/HomeBuyer'))
      },
      {
        path: '/buyers/dashboard',
        exact: true,
        component: lazy(() => import('src/views/HomeOrderToCashDebtor'))
      },
      {
        path: '/buyers/business-relationships/:relationshipId/pay-with-bank-transfer',
        exact: true,
        component: lazy(() => import('src/views/PayWithBankTransfer'))
      },
      {
        path: '/buyers/archive',
        exact: true,
        component: lazy(() => import('src/views/BuyerAuctionsArchive'))
      },
      {
        path: '/companies',
        exact: true,
        component: lazy(() => import('src/views/CompaniesView'))
      },
      {
        path: '/callcenters/combine',
        exact: true,
        component: lazy(() => import('src/views/CombineBuyersCallcenter'))
      },
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('src/views/Profile'))
      },
      {
        path: '/notifications',
        exact: true,
        component: lazy(() => import('src/views/ManageNotifications'))
      },
      {
        path: '/externalServices',
        exact: true,
        component: lazy(() => import('src/views/FattureInCloudSettings'))
      },
      {
        path: '/assistance',
        exact: true,
        component: lazy(() => import('src/views/AssistancePage'))
      },
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('src/views/Calendar'))
      },
      // {
      //   path: '/integrations',
      //   exact: true,
      //   component: lazy(() => import('src/views/PlaceholderView'))
      // },
      {
        path: '/groups',
        exact: true,
        component: lazy(() => import('src/views/PlaceholderView'))
      },
      {
        path: '/report',
        exact: true,
        component: lazy(() => import('src/views/CallcenterReport'))
      },
      {
        path: '/requestReport',
        exact: true,
        component: lazy(() => import('src/views/RequestReport'))
      },
      {
        path: '/manageMarketplaces',
        exact: true,
        component: lazy(() => import('src/views/ManageMarketplaces'))
      },
      // {
      //   path: '/marketplace',
      //   exact: true,
      //   component: lazy(() => import('src/views/MarketplacesView'))
      // },
      // {
      //   path: '/marketplace/details/:marketplaceId',
      //   exact: true,
      //   component: lazy(() => import('src/views/MarketplaceDetailsViewNew'))
      // },
      {
        path: '/scf/creditCardPrograms/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/ActiveCreditCardPrograms'))
      },
      {
        path: '/scf/creditCardPrograms/:marketplaceId/expressDynamicDiscounting',
        exact: true,
        component: lazy(() => import('src/views/ExpressDDBuyerSettings'))
      },
      {
        path: '/scf/creditCardPrograms/:marketplaceId/:programId',
        exact: true,
        component: lazy(() => import('src/views/PayInvoicesWithCreditCard'))
      },
      {
        path: '/scf/activePrograms',
        exact: true,
        component: lazy(() => import('src/views/SCFMultiOptionMarketplaces'))
      },
      {
        path: '/scf/activePrograms/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/ActiveSCFPrograms'))
      },
      {
        path: '/integrations/all',
        exact: true,
        component: lazy(() => import('src/views/GeneralIntegrations'))
      },
      {
        path: '/integrations/technicalServices',
        exact: true,
        component: lazy(() => import('src/views/TechnicalIntegrations'))
      },
      {
        path: '/integrations/fatture-in-cloud',
        exact: true,
        component: lazy(() => import('src/views/FattureInCloudSettings'))
      },
      {
        path: '/integrations',
        exact: true,
        component: lazy(() => import('src/views/Integrations'))
      },
      {
        path: '/scf/handleSuppliers',
        exact: true,
        component: lazy(() => import('src/views/SCFHandleSuppliersList'))
      },
      {
        path: '/scf/creditCardPrograms',
        exact: true,
        component: lazy(() => import('src/views/CreditCardProgramsList'))
      },
      {
        path: '/scf/creditCardPrograms/:marketplaceId/payments/archive',
        exact: true,
        component: lazy(() => import('src/views/CreditCardPaymentsArchive'))
      },
      {
        path: '/scf/handleSuppliers/:multiOptionMarketplaceId',
        exact: true,
        component: lazy(() => import('src/views/SCFHandleSuppliersDetails'))
      },
      {
        path: '/scf/confirmingPrograms/:programId',
        exact: true,
        component: lazy(() => import('src/views/ConfirmingProgramDetail'))
      },
      {
        path: '/scf/confirmingPrograms/:programId/addSuppliers',
        exact: true,
        component: lazy(() => import('src/views/AddSuppliersToConfirmingProgram'))
      },
      {
        path: '/multioptionmarketplace/:marketplaceId/suppliers/scf',
        exact: true,
        component: lazy(() => import('src/views/SupplierMarketplaceViewMultiOption'))
      },
      {
        path: '/multioptionmarketplace/:marketplaceId/:optionSelected/:optionId/confirmation/:requestId',
        exact: true,
        component: lazy(() => import('src/views/SupplierConfirmationViewMultiOption'))
      },
      {
        path: '/multioptionmarketplace/details/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/SupplierMarketplaceRecapMultiOption'))
      },
      {
        path: '/multioptionmarketplace/details/:marketplaceId/addConfirmingProgram',
        exact: true,
        component: lazy(() => import('src/views/AddConfirmingProgram'))
      },
      {
        path: '/marketplace/details/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/SupplierMarketplaceView'))
      },
      {
        path: '/marketplace/buyer/default',
        exact: true,
        component: lazy(() => import('src/views/MarketplaceBuyerDefault'))
      },
      {
        path: '/marketplace/buyer/details/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/MarketplaceBuyerDetailsView'))
      },
      {
        path: '/multioptionmarketplace/buyer/details/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/MultiOptionMarketplaceBuyerDetailsView'))
      },
      {
        path: '/marketplace/buyer/details/:marketplaceId/invoices',
        exact: true,
        component: lazy(() => import('src/views/MarketplaceBuyerInvoicesDetailsView'))
      },
      {
        path: '/multioptionmarketplace/buyer/details/:multioptionMarketplaceId/invoices',
        exact: true,
        component: lazy(() => import('src/views/MultiOptionMarketplaceBuyerInvoicesDetailsView'))
      },
      {
        path: '/marketplace/buyer/settings/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/MarketplaceBuyerSettingsView'))
      },
      {
        path: '/multioptionmarketplace/buyer/:multioptionMarketplaceId/settings/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/MarketplaceBuyerSettingsView'))
      },
      {
        path: '/onboarding/registry',
        exact: true,
        component: lazy(() => import('src/views/RegistryLoaderView'))
      },
      {
        path: '/onboarding/files',
        exact: true,
        component: lazy(() => import('src/views/OnboardingLoaderView'))
      },
      {
        path: '/buyersAdded',
        exact: true,
        component: lazy(() => import('src/views/BuyerAddedPage'))
      },
      {
        path: '/prospects',
        exact: true,
        component: lazy(() => import('src/views/Prospects'))
      },
      {
        path: '/activeAuctionResults',
        exact: true,
        component: lazy(() => import('src/views/AuctionResults'))
      },
      {
        path: '/actualCreditCardResults',
        exact: true,
        component: lazy(() => import('src/views/ActualResultsCreditCard'))
      },
      {
        path: '/allRoundDeadlines',
        exact: true,
        component: lazy(() => import('src/views/HandleRoundCalendar'))
      },
      {
        path: '/surveyResults',
        exact: true,
        component: lazy(() => import('src/views/SurveyResults'))
      },
      {
        path: '/auctions',
        exact: true,
        component: lazy(() => import('src/views/AuctionState'))
      },
      {
        path: '/auctions/simulations',
        exact: true,
        component: lazy(() => import('src/views/AuctionSimulations'))
      },
      {
        path: '/suppliers/:supplierId/history',
        exact: true,
        component: lazy(() => import('src/views/SupplierHistory'))
      },
      {
        path: '/suppliers/all/histories',
        exact: true,
        component: lazy(() => import('src/views/AllSuppliersHistory'))
      },
      {
        path: '/suppliers/pendingRegistrations',
        exact: true,
        component: lazy(() => import('src/views/PendingRegistrations'))
      },
      {
        path: '/suppliers/withRiba',
        exact: true,
        component: lazy(() => import('src/views/HandleConfirmedSuppliers'))
      },
      {
        path: '/auctions/archive',
        exact: true,
        component: lazy(() => import('src/views/AuctionsArchive'))
      },
      {
        path: '/emails/templates',
        exact: true,
        component: lazy(() => import('src/views/PlaceholderView'))
      },
      {
        path: '/callcenters/onboarding',
        exact: true,
        component: lazy(() => import('src/views/CallcenterReport'))
      },
      {
        path: '/helpCenter',
        component: lazy(() => import('src/components/Redirect')),
        loc: 'https://help.plusadvance.com'
      },
      {
        path: '/invoicesUpload',
        exact: true,
        component: lazy(() => import('src/views/AddInvoicesMultipleOption'))
      },
      {
        path: '/receivable-invoices/upload',
        exact: true,
        component: lazy(() => import('src/views/UploadReceivableInvoices'))
      },
      {
        path: '/receivable-invoices/archive',
        exact: true,
        component: lazy(() => import('src/views/ReceivableInvoicesArchive'))
      },
      {
        path: '/receivable-invoices/buyers',
        exact: true,
        component: lazy(() => import('src/views/BuyersReport'))
      },
      {
        path: '/receivable-invoices/handleBuyers',
        exact: true,
        component: lazy(() => import('src/views/O2CHandleBuyers'))
      },
      {
        path: '/integrations/collection-services',
        exact: true,
        component: lazy(() => import('src/views/CollectionServices'))
      },
      {
        path: '/invoicesApproval',
        exact: true,
        component: lazy(() => import('src/views/ApproveInvoices'))
      },
      {
        path: '/demo/home',
        exact: true,
        component: lazy(() => import('src/views/DemoHome'))
      },
      {
        path: '/programsConfiguration',
        exact: true,
        component: lazy(() => import('src/views/ScenarioConfiguration'))
      },
      {
        path: '/mastercard-track/supplier',
        exact: true,
        component: lazy(() => import('src/components/MastercardTrackSwitcher'))
      },
      {
        path: '/mastercard-track/supplier/activation',
        exact: true,
        component: lazy(() => import('src/views/MasterCardTrackSupplierView'))
      },
      {
        path: '/mastercard-track/supplier/profile',
        exact: true,
        component: lazy(() => import('src/views/MasterCardTrackSupplierProfile'))
      },
      {
        path: '/signDocument/:documentId',
        exact: true,
        component: lazy(() => import('src/views/SignDocument'))
      },
      {
        path: '/cross-border/marketplaces',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderMarketplaces'))
      },
      {
        path: '/cross-border/marketplaces/:marketplaceId/payInvoices',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderPayment'))
      },
      {
        path: '/cross-border/payments/:paymentId',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderPaymentRecap'))
      },
      {
        path: '/cross-border/archive',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderPaymentsArchive'))
      },
      {
        path: '/cross-border/suppliers',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderSuppliersList'))
      },
      {
        path: '/cross-border/suppliers/:marketplaceId',
        exact: true,
        component: lazy(() => import('src/views/CrossBorderHandleSuppliersDetails'))
      },
      {
        path: '/order-to-cash/program/:programId/payment-methods-saved',
        exact: true,
        component: lazy(() => import('src/views/SavedPaymentMethods'))
      },
      {
        path: '/order-to-cash/program/:programId/payable-invoices',
        exact: true,
        component: lazy(() => import('src/views/O2CPayableInvoices'))
      },
      {
        path: '/order-to-cash/payments-methods',
        exact: true,
        component: lazy(() => import('src/views/PaymentMethodsO2C'))
      },
      {
        path: '/order-to-cash/buyer/payments-archive',
        exact: true,
        component: lazy(() => import('src/views/BuyerPaymentsArchive'))
      },
      {
        path: '/receivable-invoices/incoming-payments',
        exact: true,
        component: lazy(() => import('src/views/OrderToCashReceivedPayments'))
      },
      {
        path: '/esg-assessment/standard-ethics',
        exact: true,
        component: lazy(() => import('src/views/ESGScoring'))
      },
      {
        path: '/lottery',
        exact: true,
        component: lazy(() => import('src/views/Lottery'))
      }
    ]
  }
]
